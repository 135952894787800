<template>
  <div class="card">
    <p class="title"><i class="fa fa-th-large fa-lg"></i>嘌呤</p>
    <i class='delete-icon el-icon-close' @click="() => {$emit('onClose')}"></i>
    <el-form :model="ruleForm2" status-icon :rules="rules" ref="ruleForm2" label-width="100px" class="demo-ruleForm">
      <el-form-item label="名字" prop="name">
        <el-input clearable v-model="ruleForm2.name" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="每100g食物中嘌呤含量" prop="content">
        <el-input clearable type='textarea' autosize v-model="ruleForm2.content" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="建议" prop="recommend">
        <el-input clearable type='textarea' autosize v-model="ruleForm2.recommend" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button v-if="!isModify" type="primary" @click="saveListByForm('ruleForm2')">添加到列表</el-button>
        <el-button @click="resetForm">重 置</el-button>
        <el-button type="primary" @click="submitForm('ruleForm2')">确 定</el-button>
      </el-form-item>
    </el-form>
    <Table
      v-if="!isModify"
      class=""
      hiddenMove
      title="待提交数据列表"
      hiddenPagination
      :tableData="dataList"
      :tableCols="getCols()"
      :onDelete="handleDelete"
    />
  </div>
</template>

<script>
import Table from '../table'
import { cols } from './config'
export default {
  name: 'Form',
  components: {
    Table
  },
  props: {
    data: {
      default: {}
    },
    isModify: {
      default: false
    }
  },
  created () {
    if (!this.isModify) return
    const { name, content, recommend } = this.data
    this.ruleForm2 = {
      name,
      content,
      recommend
    }
  },
  data () {
    return {
      dataList: [],
      ruleForm2: {
        content: '',
        name: '',
        recommend: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入嘌呤名字', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '请输入嘌呤内容', trigger: 'blur' }
        ],
        recommend: [
          { message: '请输入嘌呤内容', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    handleDelete (data) {
      const { name, content, recommend } = data
      const index = this.dataList
        .findIndex(x => x.name === name && x.content === content && x.recommend === recommend)
      this.dataList.splice(index, 1)
    },
    getCols () {
      return cols
    },
    saveListByForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const obj = {}
          for (const key in this.ruleForm2) {
            obj[key] = this.ruleForm2[key]
          }
          this.dataList.push(obj)
          this.resetForm()
        } else {
          this.$message.error('添加失败,请输入必填选项')
        }
      })
    },
    submitForm (formName) {
      if (this.dataList.length) {
        return this.$emit('successAdd', this.dataList)
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const obj = {}
          for (const key in this.ruleForm2) {
            obj[key] = this.ruleForm2[key]
          }
          this.isModify ? this.$emit('onModify', { ...this.data, ...obj }) : this.$emit('successAdd', obj)
        } else {
          this.$message.error('添加失败')
        }
      })
    },
    resetForm () {
      this.ruleForm2 = {
        content: '',
        name: '',
        recommend: ''
      }
    }
  }
}
</script>
