import axios, { reLogin } from '../axios'

/**
 * @param {object} params
 * @param {string} content
 * @param {string} name
 * @param {string} recommend
 */
async function purineCreate (params) {
  if (!params) return null
  try {
    const { data } = await axios.post('/purine/create', params)
    if (data.status !== 'ok') reLogin(data.errorMsg)
    return data
  } catch (error) {
    return null
  }
}
/**
 * @param {object} params
 * @param {number} pn
 * @param {number} ps
 * @param {string} keyword
 */
async function purineList (params) {
  if (!params) return null
  try {
    const { data } = await axios.get('/purine/list', { params })
    if (data.status !== 'ok') reLogin(data.errorMsg)
    return data
  } catch (error) {
    return null
  }
}
/**
 * @param {number} id
 */
async function purineDelete (id) {
  if (!id) return null
  try {
    const { data } = await axios.delete(`/purine/${id}`)
    if (data.status !== 'ok') reLogin(data.errorMsg)
    return data
  } catch (error) {
    return null
  }
}

async function purinePut (params) {
  try {
    const { data } = await axios.put('/purine/put', params)
    if (data.status !== 'ok') reLogin(data.errorMsg)
    return data
  } catch (error) {
    return null
  }
}
export {
  purineCreate,
  purineList,
  purineDelete,
  purinePut
}
